<template>
    <b-row sm="12">
        <b-col sm="4">
            <b-form-group label="Capital" label-for="capital">
                <money class="form-control" v-model="policy_data.capitals" v-bind="money" :class="validation_policy_data.capitals ? 'is-invalid': ''"/>

                <small v-if="validation_policy_data.capitals" class="text-danger"> {{
                        validation_policy_data.capitals
                    }}</small>
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay, BFormRadio, BButton, BFormTextarea} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "AccidentsParticular",
    components: {
        BFormGroup,
        BOverlay,
        BRow,
        BCol,
        BFormInput,
        BFormRadio,
        BButton,
        BFormTextarea,

        vSelect,
    },
    data() {
        return {
            showLoading: false,
        }
    },
    computed: {
        ...mapState('insurancePolicies', ['validation_policy_data']),
        ...mapFields({
            policy_data: 'policy_data',
            policy_data_capitals: 'policy_data_capitals'
        }),
    },
}
</script>