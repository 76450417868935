<template>
  <b-row sm="12">
    <b-col sm="4">
      <b-form-group label="Dades del prenedor" label-for="company-data">
        <b-form-input v-model="policy_data.taker_data" placeholder="Dades del prenedor"
                      :class="validation_policy_data.taker_data ? 'is-invalid': ''"/>
        <small v-if="validation_policy_data.taker_data" class="text-danger"> {{
            validation_policy_data.taker_data
          }}</small>
      </b-form-group>
    </b-col>
    <b-col sm="4">
      <b-form-group label="Direcció de risc" label-for="risk-address">
        <b-form-input v-model="policy_data.risk_address" placeholder="Domicili de risc"
                      :class="validation_policy_data.risk_address ? 'is-invalid': ''"/>
        <small v-if="validation_policy_data.risk_address" class="text-danger"> {{
            validation_policy_data.risk_address
          }}</small>
      </b-form-group>
    </b-col>
    <b-col sm="4">
      <b-form-group label="Activitat" label-for="capital-continent">
        <b-form-input v-model="policy_data.activity" placeholder="Activitat"
                      :class="validation_policy_data.activity ? 'is-invalid': ''"/>
        <small v-if="validation_policy_data.activity" class="text-danger">
          {{ validation_policy_data.activity }}</small>
      </b-form-group>
    </b-col>
    <b-col sm="12" class="pb-2 pt-1">
      <h4>Capitals</h4>
    </b-col>
    <b-row class="col-sm-12 row"
        v-for="(policy_data_capital, index) in policy_data_capitals"
        :key="policy_data_capital.id">
      <b-col sm="4">
        <b-form-group label="Capital" label-for="full_name">
          <b-form-input v-model="policy_data_capital.type" name="type"
                        placeholder="Capital"/>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group label="Descripció" label-for="phone">
          <b-form-input v-model="policy_data_capital.description" name="description" placeholder="Descripció"/>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-button
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
        >
          <feather-icon
              icon="XIcon"
              class="mr-25"
          />
          <span>Eliminar</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="col-sm-12">
      <b-col cols="12" class="pb-2">
        <b-button
            variant=""
            @click="addNewRow()"
        >
          <feather-icon
              icon="PlusIcon"
              class="mr-25"
          />
          <span>Afegir Capital</span>
        </b-button>
      </b-col>
    </b-row>
  </b-row>

</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay, BFormRadio, BButton} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
  getterType: 'insurancePolicies/getField',
  mutationType: 'insurancePolicies/updateField',
})

export default {
  name: "Business",
  components: {
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BFormInput,
    BFormRadio,
    BButton,

    vSelect,
  },
  data() {
    return {
      showLoading: false,
    }
  },
  computed: {
    ...mapState('insurancePolicies', ['validation_policy_data']),
    ...mapFields({
      policy_data: 'policy_data',
      policy_data_capitals: 'policy_data_capitals'
    }),
  },
  methods: {
    addNewRow() {
      this.policy_data_capitals.push({
        type: null,
        description: null,
      })
    },
    removeItem(index) {
      this.policy_data_capitals.splice(index, 1)
    },
  }
}
</script>