<template>
    <b-row sm="12">
        <b-col sm="4">
            <b-form-group label="Entitat sol·licitant" label-for="entity">
                <b-form-input v-model="policy_data.entity"
                                 placeholder="Entitat sol·licitant"
                                 :class="validation_policy_data.entity ? 'is-invalid': ''" autocomplete="off"/>
                <small v-if="validation_policy_data.entity" class="text-danger"> {{
                        validation_policy_data.entity
                    }}</small>
            </b-form-group>
        </b-col>
        <b-col sm="2">
            <b-form-group label="Data de constitució" label-for="date-start">
                <flat-pickr
                    v-model="policy_data.constitution_date"
                    class="form-control"
                    :class="validation_policy_data.constitution_date ? 'is-invalid': ''"
                    name="date"
                    placeholder="Data de constitució"
                    :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y'}"
                />
                <small v-if="validation_policy_data.constitution_date" class="text-danger">
                    {{ validation_policy_data.constitution_date }}</small>
            </b-form-group>
        </b-col>
        <b-col sm="6">
            <b-form-group label="Activitats de la sol·licitant i les seves filials" label-for="entity">
                <b-form-input v-model="policy_data.activity"
                              placeholder="Activitats de la sol·licitant i les seves filials"
                              :class="validation_policy_data.activity ? 'is-invalid': ''" autocomplete="off"/>
                <small v-if="validation_policy_data.activity" class="text-danger"> {{
                        validation_policy_data.activity
                    }}</small>
            </b-form-group>
        </b-col>
        <b-col sm="4">
            <b-form-group label="Qüestionari de la companyia" label-for="description-note">
                <b-form-file
                    v-model="policy_data.company_questions_file"
                    placeholder="Tria un document o deixa'l anar aquí..."
                    drop-placeholder="Tria un document o deixa'l anar aquí..."
                    browse-text="Seleccionar"
                />
            </b-form-group>
        </b-col>
        <b-col v-if="policy_data.company_questions" class="mr-2 ml-0">
            <a :href="baseUrl+policy_data.company_questions" target="_blank">
                <feather-icon
                    icon="EyeIcon"
                    class="cursor-pointer mt-0 mt-md-2"
                    size="25"
                />
            </a>
            <feather-icon v-if="policy_data.company_questions || policy_data.company_questions_file"
                          icon="TrashIcon"
                          class="cursor-pointer ml-1 mt-md-2"
                          size="25"
                          @click="policy_data.company_questions_file = policy_data.company_questions = null "
            />
        </b-col>
    </b-row>
</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay, BFormRadio, BButton, BFormTextarea, BFormFile} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"
import flatPickr from "vue-flatpickr-component"
import {baseUrl} from "@/constants/app"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "AdmDirectives",
    components: {
        BFormGroup,
        BOverlay,
        BRow,
        BCol,
        BFormInput,
        BFormRadio,
        BButton,
        BFormTextarea,
        BFormFile,

        vSelect,
        flatPickr
    },
    data() {
        return {
            showLoading: false,
            baseUrl
        }
    },
    computed: {
        ...mapState('insurancePolicies', ['validation_policy_data']),
        ...mapFields({
            policy_data: 'policy_data',
            policy_data_capitals: 'policy_data_capitals'
        }),
    },
}
</script>