<template>
  <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
  >
    <b-row
        v-for="(policy_document, index) in policy_documents_data.policy_documents"
        :key="policy_document.id">
      <b-col lg="3" md="12" sm="12">
        <b-form-group label="Nom Arxiu" label-for="personal_document_description">
          <b-form-input v-model="policy_document.document_name" name="legal_tutor_email"
                        placeholder="Nom Arxiu"/>
        </b-form-group>
      </b-col>
      <b-col lg="5" md="12" sm="12">
        <b-form-group label="Document" label-for="date-document">
          <b-form-file
              v-model="policy_document.document_file"
              placeholder="Tria un document o deixa'l anar aquí..."
              drop-placeholder="Tria un document o deixa'l anar aquí..."
              browse-text="Seleccionar"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="policy_document.document" lg="1" md="12" sm="12">
        <a :href="baseUrl+policy_document.document" target="_blank">
          <b-button
              variant="outline-success"
              class="mt-0 mt-md-2"
          >
            <span>Veure</span>
          </b-button>
        </a>
      </b-col>
      <b-col lg="3" md="12" sm="12" class="px-3 mt-2">
        <b-button
            variant="outline-danger"
            class="md-1"
            @click="removeItem(index, policy_document.id)"
        >
          <feather-icon
              icon="XIcon"
              class="mr-25"
          />
          <span>Eliminar</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="pb-2">
        <b-button
            variant="primary"
            @click="addNewRow"
        >
          <feather-icon
              icon="PlusIcon"
              class="mr-25"
          />
          <span>Afegir</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="policy_documents_data.policy_documents.length > 0">
      <b-col cols="12">
        <b-button variant="primary" class="mt-2 mr-1" @click="saveData">
          Guardar canvis documents
        </b-button>
      </b-col>
    </b-row>
  </b-overlay>

</template>

<script>
import {BFormGroup,BFormFile, BFormInput, BRow, BCol, BOverlay, BFormRadio, BButton} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import {baseUrl} from "@/constants/app"

const {mapFields} = createHelpers({
  getterType: 'insurancePolicies/getField',
  mutationType: 'insurancePolicies/updateField',
})

export default {
  name: "Documents",
  components: {
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BFormInput,
    BFormRadio,
    BButton,
    BFormFile,

    vSelect,
  },
  data() {
    return {
      baseUrl,
      showLoading: false,
    }
  },
  computed: {
    ...mapState('insurancePolicies', ['validation_policy_data']),
    ...mapFields({
      policy: 'policy',
      policy_documents_data: 'policy_documents_data',
    }),
  },
  methods: {
    addNewRow() {
      this.policy_documents_data.policy_documents.push({
        document_name: null,
        document_file: null,
        document: null,
        insurance_policy_id: this.policy.id
      })
    },
    removeItem(index, documentId) {
      if (documentId) {
        this.policy_documents_data.policy_documents_erased.push({id: documentId})
      }
      this.policy_documents_data.policy_documents.splice(index, 1)
    },
    saveData() {
      this.$store.dispatch('insurancePolicies/updatePolicyDocuments', {data: this.formatData()})
      .then(response => {
        this.showToast('Documentació de la pòlissa editada correctament!', 'CheckIcon', 'success')

        this.policy_documents_data.policy_documents_erased = []
        this.getPolicy()
      })
      .catch(error => {
        this.showToast('Error en editar la documentació de la pòlissa!', 'AlertOctagonIcon', 'danger')
      })

    },
    formatData() {
      var formData = new FormData()

      this.appendFormData(formData, this.policy_documents_data, '')

      return formData
    },
    appendFormData(formData, data, rootName) {
      let root = rootName || ''
      if (data instanceof File) {
        formData.append(root, data)
      } else if (Array.isArray(data)) {
        for (var i = 0; i < data.length; i++) {
          this.appendFormData(formData, data[i], root + '[' + i + ']')
        }
      } else if (typeof data === 'object' && data) {
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            if (root === '') {
              this.appendFormData(formData, data[key], key)
            } else {
              this.appendFormData(formData, data[key], `${root}[${key}]`)
            }
          }
        }
      } else {
        if (data !== null && typeof data !== 'undefined') {
          formData.append(root, data)
        } else {
          formData.append(root, '')
        }
      }
    },
    getPolicy() {
      this.$store.dispatch('insurancePolicies/getPolicy', {
        relations: ['client', 'policyData', 'policyDataCapitals', 'policyDocuments'],
        id: this.$route.params.id ?? this.policy.id
      })
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      })
    },
  }
}
</script>