<template>
    <b-row sm="12">
        <b-col sm="4">
            <b-form-group label="Nota descriptiva de les dades cadastrals de l'immobl" label-for="risk-address">
                <b-form-input v-model="policy_data.description_note" autocomplete="off"
                                 placeholder="Nota descriptiva de les dades cadastrals de l'immobl"
                                 :class="validation_policy_data.description_note ? 'is-invalid': ''"/>
                <small v-if="validation_policy_data.description_note" class="text-danger"> {{
                        validation_policy_data.description_note
                    }}</small>
            </b-form-group>
        </b-col>
        <b-col sm="4">
            <b-form-group label="Mesures de seguretat" label-for="risk-address">
                <b-form-input v-model="policy_data.security_measures" placeholder="Mesures de seguretat" autocomplete="off"
                              :class="validation_policy_data.security_measures ? 'is-invalid': ''"/>
                <small v-if="validation_policy_data.security_measures" class="text-danger"> {{
                        validation_policy_data.security_measures
                    }}</small>
            </b-form-group>
        </b-col>
        <b-col sm="4">
            <b-form-group label="Activitat" label-for="capital-continent">
                <b-form-input v-model="policy_data.activity" placeholder="Activitat" autocomplete="off"
                              :class="validation_policy_data.activity ? 'is-invalid': ''"/>
                <small v-if="validation_policy_data.activity" class="text-danger">
                    {{ validation_policy_data.activity }}</small>
            </b-form-group>
        </b-col>

    </b-row>

</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay, BFormRadio, BButton, BFormTextarea} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "Multirisk",
    components: {
        BFormGroup,
        BOverlay,
        BRow,
        BCol,
        BFormInput,
        BFormRadio,
        BButton,
        BFormTextarea,

        vSelect,
    },
    data() {
        return {
            showLoading: false,
        }
    },
    computed: {
        ...mapState('insurancePolicies', ['validation_policy_data']),
        ...mapFields({
            policy_data: 'policy_data',
            policy_data_capitals: 'policy_data_capitals'
        }),
    },
    methods: {
        addNewRow() {
            this.policy_data_capitals.push({
                type: null,
                description: null,
            })
        },
        removeItem(index) {
            this.policy_data_capitals.splice(index, 1)
        },
    }
}
</script>