<template>
    <div>
        <b-col sm="4">
            <b-form-group label="" label-for="agreement">
                <div class="demo-inline-spacing">
                    <b-form-radio v-model="policy_data.rent_location" name="rent_location"
                                  value="house">
                        Habitatge
                    </b-form-radio>
                    <b-form-radio v-model="policy_data.rent_location" name="rent_location"
                                  value="rent">
                        Local arrendat
                    </b-form-radio>
                </div>
                <small v-if="validation_policy_data.rent_location" class="text-danger">
                    {{ validation_policy_data.rent_location }}</small>
            </b-form-group>
        </b-col>
        <b-col sm="12" class="pb-2 pt-1">
            <h4>Avalistes</h4>
        </b-col>
        <b-row class="col-sm-12 row"
               v-for="(policy_data_rent_guarantor, index) in policy_data_rent_guarantors"
               :key="policy_data_rent_guarantor.id">
            <b-col sm="6">
                <b-form-group label="Nom complet" label-for="full_name">
                    <b-form-input v-model="policy_data_rent_guarantor.name" name="name" autocomplete="off"
                                  placeholder="Nom complet"/>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="DNI" label-for="phone">
                    <b-form-input v-model="policy_data_rent_guarantor.dni" autocomplete="off" name="dni"
                                  placeholder="DNI"/>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-button
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItemGuarantor(index)"
                >
                    <feather-icon
                        icon="XIcon"
                        class="mr-25"
                    />
                    <span>Eliminar</span>
                </b-button>
            </b-col>
        </b-row>
        <b-row class="col-sm-12">
            <b-col cols="12" class="pb-2">
                <b-button
                    variant=""
                    @click="addNewRowGuarantor()"
                >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Afegir avalista</span>
                </b-button>
            </b-col>
        </b-row>
        <b-col sm="12" class="pb-2 pt-1">
            <h4>Inquilins</h4>
        </b-col>
        <div
            v-for="(policy_data_rent_tenant, index) in policy_data_rent_tenants"
            :key="policy_data_rent_tenant.id">
            <b-card border-variant="primary">
                <b-row>
                    <b-col sm="3">
                        <b-form-group label="Nom complet" label-for="full_name">
                            <b-form-input v-model="policy_data_rent_tenant.name" name="name" autocomplete="off"
                                          placeholder="Nom complet"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                        <b-form-group label="DNI" label-for="phone">
                            <b-form-input v-model="policy_data_rent_tenant.dni" autocomplete="off" name="dni"
                                          placeholder="DNI"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                        <b-form-group label="Document" label-for="document">
                            <b-form-file
                                v-model="policy_data_rent_tenant.document_file"
                                placeholder="Tria un document o deixa'l anar aquí..."
                                drop-placeholder="Tria un document o deixa'l anar aquí..."
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="policy_data_rent_tenant.document" class="mr-2 ml-0">
                        <a :href="baseUrl+policy_data_rent_tenant.document" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 mt-md-2"
                                size="25"
                            />
                        </a>
                        <feather-icon v-if="policy_data_rent_tenant.document || policy_data_rent_tenant.document_file"
                                      icon="TrashIcon"
                                      class="cursor-pointer ml-1 mt-md-2"
                                      size="25"
                                      @click="policy_data_rent_tenant.document_file = policy_data_rent_tenant.document = null "
                        />
                    </b-col>
                    <b-col sm="4">
                        <b-form-group label="Última nòmina" label-for="document">
                            <b-form-file
                                v-model="policy_data_rent_tenant.last_payroll_file"
                                placeholder="Tria un document o deixa'l anar aquí..."
                                drop-placeholder="Tria un document o deixa'l anar aquí..."
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="policy_data_rent_tenant.last_payroll" class="mr-2 ml-0">
                        <a :href="baseUrl+policy_data_rent_tenant.last_payroll" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 mt-md-2"
                                size="25"
                            />
                        </a>
                        <feather-icon v-if="policy_data_rent_tenant.last_payroll || policy_data_rent_tenant.last_payroll_file"
                                      icon="TrashIcon"
                                      class="cursor-pointer ml-1 mt-md-2"
                                      size="25"
                                      @click="policy_data_rent_tenant.last_payroll_file = policy_data_rent_tenant.last_payroll = null "
                        />
                    </b-col>
                    <b-col sm="4">
                        <b-form-group label="Penúltima nòmina" label-for="document">
                            <b-form-file
                                v-model="policy_data_rent_tenant.penultimate_payroll_file"
                                placeholder="Tria un document o deixa'l anar aquí..."
                                drop-placeholder="Tria un document o deixa'l anar aquí..."
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="policy_data_rent_tenant.penultimate_payroll" class="mr-2 ml-0">
                        <a :href="baseUrl+policy_data_rent_tenant.penultimate_payroll" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 mt-md-2"
                                size="25"
                            />
                        </a>
                        <feather-icon v-if="policy_data_rent_tenant.penultimate_payroll || policy_data_rent_tenant.penultimate_payroll_file"
                                      icon="TrashIcon"
                                      class="cursor-pointer ml-1 mt-md-2"
                                      size="25"
                                      @click="policy_data_rent_tenant.penultimate_payroll_file = policy_data_rent_tenant.penultimate_payroll = null "
                        />
                    </b-col>
                    <b-row class="col-sm-12">
                    <b-col sm="2">
                        <b-button
                            variant="outline-danger"
                            class="mt-0 mt-md-2"
                            @click="removeItemTenant(index, policy_data_rent_tenant.id)"
                        >
                            <feather-icon
                                icon="XIcon"
                                class="mr-25"
                            />
                            <span>Eliminar</span>
                        </b-button>
                    </b-col>
                    </b-row>
                </b-row>
            </b-card>
        </div>


        <b-row class="col-sm-12">
            <b-col cols="12" class="pb-2">
                <b-button
                    variant=""
                    @click="addNewRowTenant()"
                >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Afegir inquilí</span>
                </b-button>
            </b-col>
        </b-row>
    </div>

</template>

<script>
import {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BOverlay,
    BFormRadio,
    BButton,
    BFormTextarea,
    BFormRadioGroup,
    BFormFile,
    BCard
} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"
import flatPickr from "vue-flatpickr-component"
import {baseUrl} from "@/constants/app"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "AdmDirectives",
    components: {
        BFormGroup,
        BOverlay,
        BRow,
        BCol,
        BFormInput,
        BFormRadio,
        BButton,
        BFormTextarea,
        BFormRadioGroup,
        BFormFile,
        BCard,

        vSelect,
        flatPickr
    },
    data() {
        return {
            showLoading: false,
            baseUrl
        }
    },
    computed: {
        ...mapState('insurancePolicies', ['validation_policy_data']),
        ...mapFields({
            policy_data: 'policy_data',
            policy_data_rent_guarantors: 'policy_data_rent_guarantors',
            policy_data_rent_tenants: 'policy_data_rent_tenants',
            policy_data_rent_tenants_erased: 'policy_data_rent_tenants_erased'
        }),
    },
    methods: {
        addNewRowGuarantor() {
            this.policy_data_rent_guarantors.push({
                name: null,
                dni: null,
            })
        },
        removeItemGuarantor(index) {
            this.policy_data_rent_guarantors.splice(index, 1)
        },
        addNewRowTenant() {
            this.policy_data_rent_tenants.push({
                name: null,
                dni: null,
                document_file: null,
                document: null,
                last_payroll_file: null,
                last_payroll: null,
                penultimate_payroll_file: null,
                penultimate_payroll: null,
            })
        },
        removeItemTenant(index,tenantId) {
            if (tenantId) {
                this.policy_data_rent_tenants_erased.push({id: tenantId})
            }
            this.policy_data_rent_tenants.splice(index, 1)
        },
    }
}
</script>