<template>
  <b-row sm="12">
    <b-col sm="12">
      <b-form-group label="Risc que cobrir" label-for="capitals">
        <b-form-input v-model="policy_data.covered_risk" placeholder="Risc que cobrir"
                      :class="validation_policy_data.covered_risk ? 'is-invalid': ''"/>
        <small v-if="validation_policy_data.covered_risk" class="text-danger"> {{
            validation_policy_data.covered_risk
          }}</small>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
  getterType: 'insurancePolicies/getField',
  mutationType: 'insurancePolicies/updateField',
})

export default {
  name: "RCProfessional",
  components: {
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BFormInput,

    vSelect,
  },
  data() {
    return {
      showLoading: false,
      activities: [],
    }
  },
  computed: {
    ...mapState('insurancePolicies', ['validation_policy_data']),
    ...mapFields({
      policy_data: 'policy_data'
    }),
  },
}
</script>