<template>
    <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
    >
        <div>
            <b-row>
                <b-col sm="12" class="pb-2">
                    <h4>Informació de la pòlissa</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="3">
                    <b-form-group label="Cia" label-for="cia">
                        <b-form-input v-model="policy.company" placeholder="Cia"
                                      :class="validation_policy.company ? 'is-invalid': ''"/>
                        <small v-if="validation_policy.company" class="text-danger">
                            {{ validation_policy.company }}
                        </small>
                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    <b-form-group label="Numero pòlissa" label-for="risk">
                        <b-form-input v-model="policy.policy_number" placeholder="Numero pòlissa"
                                      :class="validation_policy.policy_number ? 'is-invalid': ''"/>
                        <small v-if="validation_policy.policy_number" class="text-danger">
                            {{ validation_policy.policy_number }}
                        </small>
                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    <b-form-group label="Risc" label-for="risk">
                        <b-form-input v-model="policy.risk" placeholder="Risc"
                                      :class="validation_policy.risk ? 'is-invalid': ''"/>
                        <small v-if="validation_policy.risk" class="text-danger">
                            {{ validation_policy.risk }}
                        </small>
                    </b-form-group>
                </b-col>
                <b-col lg="3" md="12" sm="12">
                    <b-form-group label="Període" label-for="value">
                        <v-select
                            v-model="policy.period"
                            :options="policiesPeriods"
                            label="name"
                            :reduce="(option) => option.value"
                        />
                        <small v-if="validation_policy.period" class="text-danger">
                            {{ validation_policy.period }}
                        </small>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col lg="3" md="12" sm="12">
                    <b-form-group
                        label-for="date-effect"
                        label="Primer efecte"
                    >
                        <flat-pickr
                            v-model="policy.date_first_effect"
                            class="form-control"
                            :class="validation_policy.date_first_effect ? 'is-invalid': ''"
                            name="date"
                            placeholder="Primer efecte"
                            :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                        />
                        <small v-if="validation_policy.date_first_effect" class="text-danger">
                            {{ validation_policy.date_first_effect }}
                            </small>
                    </b-form-group>
                </b-col>
                <b-col lg="3" md="12" sm="12">
                    <b-form-group
                        label-for="date-effect"
                        label="Data efecte"
                    >
                        <flat-pickr
                            v-model="policy.date_effect"
                            class="form-control"
                            :class="validation_policy.date_effect ? 'is-invalid': ''"
                            name="date"
                            placeholder="Data efecte"
                            :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                        />
                        <small v-if="validation_policy.date_effect" class="text-danger">
                            {{ validation_policy.date_effect }}
                        </small>
                    </b-form-group>
                </b-col>
                <b-col lg="3" md="12" sm="12">
                    <b-form-group
                        label-for="date-effect"
                        label="Data venciment"
                    >
                        <flat-pickr
                            v-model="policy.date_expiration"
                            class="form-control"
                            :class="validation_policy.date_expiration ? 'is-invalid': ''"
                            name="date"
                            placeholder="Data venciment"
                            :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                        />
                        <small v-if="validation_policy.date_expiration" class="text-danger">
                            {{ validation_policy.date_expiration }}
                        </small>
                    </b-form-group>
                </b-col>
                <b-col lg="3" md="12" sm="12">
                    <b-form-group
                        label-for="date-payment"
                        label="Data pagament"
                    >
                        <flat-pickr
                            v-model="policy.date_payment"
                            class="form-control"
                            :class="validation_policy.date_payment ? 'is-invalid': ''"
                            name="date"
                            placeholder="Data pagament"
                            :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                        />
                        <small v-if="validation_policy.date_payment" class="text-danger">
                            {{ validation_policy.date_payment}}
                        </small>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col lg="3" md="12" sm="12">
                    <b-form-group label="Valor" label-for="value">
                        <money class="form-control" v-model="policy.value" v-bind="money" :class="validation_policy.amount ? 'is-invalid': ''"/>
                        <small v-if="validation_policy.amount" class="text-danger">
                            {{ validation_policy.amount }}
                        </small>
                    </b-form-group>
                </b-col>

                <b-col lg="3" md="12" sm="12">
                    <b-form-group label="Estat" label-for="value">
                        <select v-model="policy.status" class="custom-select" :class="'background-' + policy.status">
                            <option v-for="status in policiesStatus"
                                    :key="status.value"
                                    :selected="status.value === policy.status"
                                    :value="status.value"> {{ status.name }}
                            </option>
                        </select>
                        <small v-if="validation_policy.status" class="text-danger">
                            {{ validation_policy.status }}
                        </small>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
    </b-overlay>
</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay, BFormRadio, BButton} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"
import {policiesPeriods, policiesStatus} from "@/constants/insurancePolicies"
import flatPickr from "vue-flatpickr-component"
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "FormPolicy",
    components: {
        BFormGroup,
        BOverlay,
        BRow,
        BCol,
        BFormInput,
        flatPickr,
        vSelect,
    },
    data() {
        return {
            showLoading: false,

            policiesStatus,
            policiesPeriods,
            SpanishLocale
        }
    },
    computed: {
        ...mapState('insurancePolicies', ['validation_policy']),
        ...mapFields({
            policy: 'policy'
        }),
    },
    methods: {}
}
</script>
<style lang="scss">

.background-in_progress {
    color: #ffc107;
    border: 1px solid #ffc107;
}

.background-accepted {
    color: #28c76f;
    border: 1px solid #28c76f;
}

.background-sent_to_client {
    color: #00cfe8;
    border: 1px solid #00cfe8;
}

.background-anulled {
    color: #82868b;
    border: 1px solid #82868b;
}

.background-denied {
    color: #ea5455;
    border: 1px solid #ea5455;
}

select option[value="in_progress"] {
    color: #ffc107 !important;
    background: rgba(255, 159, 67, 0.12);
}

select option[value="accepted"] {
    color: #28c76f !important;
    background: rgba(40, 199, 111, 0.12);
}

select option[value="sent_to_client"] {
    color: #00cfe8 !important;
    background: rgba(0, 207, 232, 0.12);
}

select option[value="anulled"] {
    color: #82868b !important;
    background: rgba(130, 134, 139, 0.12);
}

select option[value="denied"] {
    color: #ea5455 !important;
    background: rgba(234, 84, 85, 0.12);
}
</style>