<template>
    <b-row sm="12">
      <b-col sm="3">
        <b-form-group label="Dades de l'empresa" label-for="company-data">
          <b-form-input v-model="policy_data.company_data" placeholder="Dades de l'empresa"
                        :class="validation_policy_data.company_data ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.company_data" class="text-danger"> {{ validation_policy_data.company_data }}</small>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Activitat" label-for="capital-continent">
          <b-form-input v-model="policy_data.activity" placeholder="Activitat"
                        :class="validation_policy_data.activity ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.activity" class="text-danger">
            {{ validation_policy_data.activity }}</small>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Conveni" label-for="agreement">
          <b-form-input v-model="policy_data.agreement" placeholder="Conveni"
                        :class="validation_policy_data.agreement ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.agreement" class="text-danger">
            {{ validation_policy_data.agreement }}</small>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Nombre de treballadors" label-for="workers-number">
          <b-form-input v-model="policy_data.workers_number" type="number" placeholder="Nombre de treballadors"
                        :class="validation_policy_data.workers_number ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.workers_number" class="text-danger"> {{
              validation_policy_data.workers_number
            }}</small>
        </b-form-group>
      </b-col>
    </b-row>
</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
  getterType: 'insurancePolicies/getField',
  mutationType: 'insurancePolicies/updateField',
})

export default {
  name: "AccidentsContract",
  components: {
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BFormInput,

    vSelect,
  },
  data() {
    return {
      showLoading: false,
      activities: [],
    }
  },
  computed: {
    ...mapState('insurancePolicies', ['validation_policy_data']),
    ...mapFields({
      policy_data: 'policy_data'
    }),
  },
}
</script>