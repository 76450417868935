<template>
    <b-row sm="12">
      <b-col sm="3">
        <b-form-group label="DNI" label-for="dni">
          <b-form-input v-model="policy_data.dni" placeholder="DNI" autocomplete="off"
                        :class="validation_policy_data.dni ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.dni" class="text-danger"> {{ validation_policy_data.dni }}</small>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Capital de continent" label-for="capital-continent">
            <money class="form-control" v-model="policy_data.mainland_capital" v-bind="money" :class="validation_policy_data.mainland_capital ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.mainland_capital" class="text-danger">
            {{ validation_policy_data.mainland_capital }}</small>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Capital de contingut" label-for="capital-content">
            <money class="form-control" v-model="policy_data.content_capital" v-bind="money" :class="validation_policy_data.content_capital ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.content_capital" class="text-danger">
            {{ validation_policy_data.content_capital }}</small>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Valor contingut" label-for="content-value">
            <money class="form-control" v-model="policy_data.content_value" v-bind="money" :class="validation_policy_data.content_value ? 'is-invalid': ''"/>
            <small v-if="validation_policy_data.content_value" class="text-danger"> {{
              validation_policy_data.content_value
            }}</small>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Domicili de risc" label-for="risk-address">
          <b-form-input v-model="policy_data.risk_address" placeholder="Domicili de risc"
                        :class="validation_policy_data.risk_address ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.risk_address" class="text-danger"> {{
              validation_policy_data.risk_address
            }}</small>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Referència cadastral" label-for="cadastral-reference">
          <b-form-input v-model="policy_data.cadastral_reference" placeholder="Referència cadastral"
                        :class="validation_policy_data.cadastral_reference ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.cadastral_reference" class="text-danger">
            {{ validation_policy_data.cadastral_reference }}</small>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group label="Mesures de seguretat" label-for="security-measures">
          <b-form-input v-model="policy_data.security_measures" placeholder="Mesures de seguretat"
                        :class="validation_policy_data.security_measures ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.security_measures" class="text-danger">
            {{ validation_policy_data.security_measures }}</small>
        </b-form-group>
      </b-col>
    </b-row>
</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
  getterType: 'insurancePolicies/getField',
  mutationType: 'insurancePolicies/updateField',
})

export default {
  name: "InsuranceHouse",
  components: {
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BFormInput,

    vSelect,
  },
  data() {
    return {
      showLoading: false,
      activities: [],
    }
  },
  computed: {
    ...mapState('insurancePolicies', ['validation_policy_data']),
    ...mapFields({
      policy_data: 'policy_data'
    }),
  },
}
</script>