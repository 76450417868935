<template>
    <b-row sm="12">
        <b-col sm="10">
            <b-form-group label="Itinerari del viatge" label-for="route">
                <b-form-input v-model="policy_data.travel_route" placeholder="Itinerari del viatge" autocomplete="off"
                              :class="validation_policy_data.travel_route ? 'is-invalid': ''"/>
                <small v-if="validation_policy_data.travel_route" class="text-danger"> {{
                        validation_policy_data.travel_route
                    }}</small>
            </b-form-group>
        </b-col>
        <b-col sm="2">
            <b-form-group label="Nº de persones" label-for="person-number">
                <b-form-input v-model="policy_data.travel_passengers_number" placeholder="Nº de persones" autocomplete="off"
                              :class="validation_policy_data.travel_passengers_number ? 'is-invalid': ''"/>
                <small v-if="validation_policy_data.travel_passengers_number" class="text-danger"> {{
                        validation_policy_data.travel_passengers_number
                    }}</small>
            </b-form-group>
        </b-col>
        <b-col sm="3">
            <b-form-group label="Data de sortida" label-for="date-start">
                    <flat-pickr
                        v-model="policy_data.travel_date_start"
                        class="form-control"
                        :class="validation_policy_data.travel_date_start ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data de sortida"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_policy_data.travel_date_start" class="text-danger">
                        {{ validation_policy_data.travel_date_start }}</small>
                </b-form-group>
        </b-col>
        <b-col sm="3">
            <b-form-group label="Data de retorn" label-for="date-start">
                <flat-pickr
                    v-model="policy_data.travel_date_end"
                    class="form-control"
                    :class="validation_policy_data.travel_date_end ? 'is-invalid': ''"
                    name="date"
                    placeholder="Data de retorn"
                    :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y'}"
                />
                <small v-if="validation_policy_data.travel_date_end" class="text-danger">
                    {{ validation_policy_data.travel_date_end }}</small>
            </b-form-group>
        </b-col>
        <b-col sm="12" class="pb-2 pt-1">
            <h4>Persones</h4>
        </b-col>
        <b-row class="col-sm-12 row"
               v-for="(policy_data_travel_person, index) in policy_data_travel_persons"
               :key="policy_data_travel_person.id">
            <b-col sm="4">
                <b-form-group label="Nom" label-for="full_name">
                    <b-form-input v-model="policy_data_travel_person.name" name="name" autocomplete="off"
                                  placeholder="Nom"/>
                </b-form-group>
            </b-col>
            <b-col sm="6">
                <b-form-group label="DNI" label-for="phone">
                    <b-form-input v-model="policy_data_travel_person.dni"  autocomplete="off" name="dni" placeholder="DNI"/>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-button
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                >
                    <feather-icon
                        icon="XIcon"
                        class="mr-25"
                    />
                    <span>Eliminar</span>
                </b-button>
            </b-col>
        </b-row>
        <b-row class="col-sm-12">
            <b-col cols="12" class="pb-2">
                <b-button
                    variant=""
                    @click="addNewRow()"
                >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Afegir persona</span>
                </b-button>
            </b-col>
        </b-row>
    </b-row>

</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay, BFormRadio, BButton} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"
import flatPickr from "vue-flatpickr-component"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'



const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "Business",
    components: {
        BFormGroup,
        BOverlay,
        BRow,
        BCol,
        BFormInput,
        BFormRadio,
        BButton,

        vSelect,
        flatPickr
    },
    data() {
        return {
            showLoading: false,
            CatalanLocale
        }
    },
    computed: {
        ...mapState('insurancePolicies', ['validation_policy_data']),
        ...mapFields({
            policy_data: 'policy_data',
            policy_data_travel_persons: 'policy_data_travel_persons'
        }),
    },
    methods: {
        addNewRow() {
            this.policy_data_travel_persons.push({
                name: null,
                dni: null,
            })
        },
        removeItem(index) {
            this.policy_data_travel_persons.splice(index, 1)
        },
    }
}
</script>