<template>
    <b-row sm="12">
      <b-col sm="3">
        <b-form-group label="Dades del prenedor" label-for="company-data">
          <b-form-input v-model="policy_data.taker_data" placeholder="Dades del prenedor"
                        :class="validation_policy_data.taker_data ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.taker_data" class="text-danger"> {{ validation_policy_data.taker_data }}</small>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Activitat" label-for="capital-continent">
          <b-form-input v-model="policy_data.activity" placeholder="Activitat"
                        :class="validation_policy_data.activity ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.activity" class="text-danger">
            {{ validation_policy_data.activity }}</small>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Franchise" label-for="agreement">
          <div class="demo-inline-spacing">
            <b-form-radio v-model="policy_data.franchise" name="franchise"
                          value="1">
              Sì
            </b-form-radio>
            <b-form-radio v-model="policy_data.franchise" name="franchise"
                          value="0">
              No
            </b-form-radio>
          </div>
          <small v-if="validation_policy_data.franchise" class="text-danger">
            {{ validation_policy_data.franchise }}</small>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Capital diari" label-for="workers-number">
          <b-form-input v-model="policy_data.workers_number" type="number" placeholder="Capital diari"
                        :class="validation_policy_data.workers_number ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.workers_number" class="text-danger"> {{
              validation_policy_data.workers_number
            }}</small>
        </b-form-group>
      </b-col>
    </b-row>
</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay,BFormRadio} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
  getterType: 'insurancePolicies/getField',
  mutationType: 'insurancePolicies/updateField',
})

export default {
  name: "DailyAbsense",
  components: {
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BFormInput,
    BFormRadio,

    vSelect,
  },
  data() {
    return {
      showLoading: false,
      activities: [],
    }
  },
  computed: {
    ...mapState('insurancePolicies', ['validation_policy_data']),
    ...mapFields({
      policy_data: 'policy_data'
    }),
  },
}
</script>