<template>
    <div>
        <b-card class="p-1 p-lg-0">
            <b-tabs align="left">
                <b-tab active title="Informació general" lazy>
                    <b-overlay
                        variant="white"
                        :show="showLoading"
                        spinner-variant="primary"
                        blur="0"
                        opacity=".75"
                        rounded="sm"
                    >
                        <b-row>
                            <b-col sm="8">
                                <b-form-group label="Client" label-for="value">
                                    <v-select
                                        v-model="policy.client_id"
                                        :options="clients"
                                        label="name"
                                        :reduce="(option) => option.id"
                                        :disabled="this.edit"
                                    >
                                        <template slot="option" slot-scope="option">
                                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                                        </template>
                                        <template #no-options="{ }">
                                            No s'han pogut carregar els clients
                                        </template>
                                    </v-select>
                                    <small v-if="validation_policy.client_id" class="text-danger">
                                        {{ validation_policy.client_id }}
                                    </small>

                                </b-form-group>
                            </b-col>
                            <b-col lg="4" md="12" sm="12">
                                <b-form-group label="Ram" label-for="value">
                                    <v-select
                                        v-model="policy.insurance_type"
                                        :options="insurancesTypes"
                                        label="name"
                                        :reduce="(option) => option.value"
                                    />
                                    <small v-if="validation_policy.insurance_type" class="text-danger">
                                        {{ validation_policy.insurance_type }}
                                    </small>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div v-if="policy.insurance_type === 'multirisk'">
                            <form-multirisk ref="formMultirisk"/>
                        </div>
                        <div v-if="policy.insurance_type === 'house'">
                            <form-house ref="formHouse"/>
                        </div>
                        <div v-if="policy.insurance_type === 'accidents_contract'">
                            <form-accidents-contract ref="formAccidentsContract"/>
                        </div>
                        <div v-if="policy.insurance_type === 'rc_professional'">
                            <form-rc-professional ref="formRcProfessional"/>
                        </div>
                        <div v-if="policy.insurance_type === 'rc_particular'">
                            <form-rc-particular ref="formRcParticular"/>
                        </div>
                        <div v-if="policy.insurance_type === 'daily_absence'">
                            <form-daily-absense ref="formDailyAbsence"/>
                        </div>
                        <div v-if="policy.insurance_type === 'business'">
                            <form-business ref="formBusiness"/>
                        </div>
                        <div v-if="policy.insurance_type === 'pymes'">
                            <form-pymes ref="formPymes"/>
                        </div>
                        <div v-if="policy.insurance_type === 'car_motocycle'">
                            <form-car-motocycle ref="formCarMotocycle"/>
                        </div>
                        <div v-if="policy.insurance_type === 'travel_assistence'">
                            <form-travel-assistence ref="formTravelAssistence"/>
                        </div>
                        <div v-if="policy.insurance_type === 'community'">
                            <form-community ref="formCommunity"/>
                        </div>
                        <div v-if="policy.insurance_type === 'life'">
                            <form-life ref="formLife"/>
                        </div>
                        <div v-if="policy.insurance_type === 'accidents_particular'">
                            <form-accidents-particular ref="formAccidentsParticular"/>
                        </div>
                        <div v-if="policy.insurance_type === 'health'">
                            <form-health ref="formHealth"/>
                        </div>
                        <div v-if="policy.insurance_type === 'triplesport'">
                            <form-triplesport ref="formTriplesport"/>
                        </div>
                        <div v-if="policy.insurance_type === 'adm_directives'">
                            <form-adm-directives ref="formAdmDirectives"/>
                        </div>
                        <div v-if="policy.insurance_type === 'rent'">
                            <form-rent ref="formRent"/>
                        </div>
                        <div v-if="policy.insurance_type === 'estalvi'">
                            <form-estalvi ref="formEstalvi"/>
                        </div>
                        <div v-if="policy.insurance_type === 'decennial'">
                            <form-decennial ref="formDecennial"/>
                        </div>
                        <b-col class="row col-sm-12">
                            <b-button v-if="!edit" variant="primary" class="mt-2 mr-1" @click="saveData">
                                Desar Canvis
                            </b-button>
                            <b-button v-else variant="primary" class="mt-2 mr-1" @click="saveData">
                                Actualitzar Canvis
                            </b-button>
                            <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                                Cancel·lar
                            </b-button>
                        </b-col>
                    </b-overlay>
                </b-tab>
                <b-tab v-if="policy.id" title="Dades personals" lazy>
                    <form-data-address-client/>
                </b-tab>
                <b-tab v-if="policy.id" title="Documents" lazy>
                    <form-documents ref="formDocuments"></form-documents>
                </b-tab>
                <b-tab v-if="policy.id" title="Històric de primes" lazy>
                    <form-history-policies ref="formHistoryPolicies"></form-history-policies>
                </b-tab>
            </b-tabs>
        </b-card>
        <b-card v-if="policy.id">
            <form-policy ref="formPolicy"></form-policy>
            <b-row align-h="start">
                <b-col cols="5">
                    <b-button v-if="this.edit" variant="primary" class="mt-2 mr-1" @click="saveData">
                        Desar canvis
                    </b-button>
                    <b-button v-else variant="primary" class="mt-2 mr-1" @click="saveData">
                        Actualitzar canvis
                    </b-button>
                    <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                        Cancel·lar
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {BButton, BCard, BCol, BFormGroup, BTab, BTabs, BOverlay, BRow} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import {POLICIES_LIST} from "@/router/web/constants"
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import {insurancesTypes} from "@/constants/insurancePolicies"

import FormPolicy from "@/views/insurance/policies/form/formPolicyView"
import FormHouse from "@/views/insurance/policies/form/components/formHouseView"
import FormAccidentsContract from "@/views/insurance/policies/form/components/formAccidentsContractView"
import FormRcProfessional from "@/views/insurance/policies/form/components/formRcProfessionalView"
import FormRcParticular from "@/views/insurance/policies/form/components/formRcParticularView"
import FormDailyAbsense from "@/views/insurance/policies/form/components/formDailyAbsenseView"
import FormBusiness from "@/views/insurance/policies/form/components/formBusinessView"
import FormPymes from "@/views/insurance/policies/form/components/formPymesView"
import FormCarMotocycle from "@/views/insurance/policies/form/components/formCarMotocycleView"
import FormMultirisk from "@/views/insurance/policies/form/components/formMultiriskView"
import FormTravelAssistence from "@/views/insurance/policies/form/components/formTravelView"
import FormDocuments from "@/views/insurance/policies/form/components/formDocumentsView"
import FormCommunity from "@/views/insurance/policies/form/components/formCommunityView"
import FormLife from "@/views/insurance/policies/form/components/formLifeView"
import FormAccidentsParticular from "@/views/insurance/policies/form/components/formAccidentsParticularView"
import FormHealth from "@/views/insurance/policies/form/components/formHealthView"
import FormTriplesport from "@/views/insurance/policies/form/components/formTriplesportView"
import FormAdmDirectives from "@/views/insurance/policies/form/components/formAdmDirectivesView"
import FormRent from "@/views/insurance/policies/form/components/formRentView"
import FormEstalvi from "@/views/insurance/policies/form/components/formEstalviView"
import FormDecennial from "@/views/insurance/policies/form/components/formDecennialView"

import FormHistoryPolicies from "@/views/insurance/policies/form/components/formHistoryPolicies"
import FormDataAddressClient from "@/views/clients/form/formClientPersonalDataView"

import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})


export default {
    name: "PolicyFormView",
    components: {
        BCol,
        BRow,
        BButton,
        BFormGroup,
        BCard,
        BOverlay,
        BTab,
        BTabs,

        vSelect,
        FormPolicy,
        FormHouse,
        FormAccidentsContract,
        FormRcProfessional,
        FormDailyAbsense,
        FormBusiness,
        FormPymes,
        FormCarMotocycle,
        FormMultirisk,
        FormRcParticular,
        FormTravelAssistence,
        FormCommunity,
        FormLife,
        FormAccidentsParticular,
        FormHealth,
        FormTriplesport,
        FormDocuments,
        FormAdmDirectives,
        FormRent,
        FormEstalvi,
        FormDecennial,
        FormHistoryPolicies,
        FormDataAddressClient
    },
    data() {
        return {
            users: [],
            clients: [],
            edit: false,
            showLoading: false,
            SpanishLocale,

            insurancesTypes
        }
    },
    computed: {
        ...mapState('insurancePolicies', ['validation_policy', 'validation_policy_data']),
        ...mapFields({
            policy: 'policy',
            policy_data: 'policy_data',
            policy_data_capitals: 'policy_data_capitals',
            policy_data_travel_persons: 'policy_data_travel_persons',
            policy_data_rent_guarantors: 'policy_data_rent_guarantors',
            policy_data_rent_tenants: 'policy_data_rent_tenants',
            policy_data_rent_tenants_erased: 'policy_data_rent_tenants_erased',
            policy_documents_data: 'policy_documents_data',
        }),
    },
    created() {
        this.$store.commit('insurancePolicies/RESET_STATE')
        this.getClients()
        if (this.$route.params.id) {
            this.edit = true
            this.getPolicy()
        } else {
            this.edit = false
        }
    },
    methods: {
        getPolicy() {
            this.showLoading = true
            this.$store.dispatch('insurancePolicies/getPolicy', {
                id: this.$route.params.id ?? this.policy.id,
                relations: ['client', 'client.clientPersonalData', 'policyData','policyHistory', 'policyDataCapitals', 'policyDataTravelPersons',
                    'policyDataRentGuarantors','policyDataRentTenants', 'policyDocuments'],
            })
            .then(response => {
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        getClients() {
            this.showLoading = true

            this.$store.dispatch('client/getAllClients', {
                id: this.$route.params.id,
                relations: ['responsible'],
            })
            .then(response => {
                this.clients = response
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        formatData() {
            var formData = new FormData()
            this.appendFormData(formData, this.policy, 'policy')
            this.appendFormData(formData, this.policy_data, 'policy_data')
            this.appendFormData(formData, this.policy_data_capitals, 'policy_data_capitals')
            this.appendFormData(formData, this.policy_data_travel_persons, 'policy_data_travel_persons')
            this.appendFormData(formData, this.policy_data_rent_guarantors, 'policy_data_rent_guarantors')
            this.appendFormData(formData, this.policy_data_rent_tenants, 'policy_data_rent_tenants')
            this.appendFormData(formData, this.policy_data_rent_tenants_erased, 'policy_data_rent_tenants_erased')
            return formData
        },
        appendFormData(formData, data, rootName) {
            let root = rootName || ''
            if (data instanceof File) {
                formData.append(root, data)
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    this.appendFormData(formData, data[i], root + '[' + i + ']')
                }
            } else if (typeof data === 'object' && data) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            this.appendFormData(formData, data[key], key)
                        } else {
                            this.appendFormData(formData, data[key], `${root}[${key}]`)
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data)
                } else {
                    formData.append(root, '')
                }
            }
        },
        saveData() {
            if (!this.policy.id) {
                this.$store.dispatch('insurancePolicies/createPolicy', this.formatData())
                .then(response => {

                    this.policy.id = response.id
                    this.edit = true
                    this.showToast('Pòlissa creat correctament!', 'CheckIcon', 'success')
                    this.getPolicy()
                })
                .catch(error => {
                    this.showToast('Error en crear la pòlissa!', 'AlertOctagonIcon', 'danger')
                })
            } else {
                this.$store.dispatch('insurancePolicies/updatePolicy', this.formatData())
                .then(response => {
                    this.showToast('Dades actualitzades correctament!', 'CheckIcon', 'success')
                    this.getPolicy()
                  //  this.policy_data.contract_house = response.policy_data.contract_house
                })
                .catch(error => {
                    this.showToast('Error en editar la pòlissa!', 'AlertOctagonIcon', 'danger')
                })
            }
        },
        cancel() {
            this.$router.push(POLICIES_LIST)
        },
        showToast(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: title,
                    icon: icon,
                    variant: variant,
                },
            })
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>