<template>
    <b-row sm="12">
      <b-col sm="3">
        <b-form-group label="DNI" label-for="dni">
          <b-form-input v-model="policy_data.dni" placeholder="DNI"
                        :class="validation_policy_data.dni ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.dni" class="text-danger"> {{ validation_policy_data.dni }}</small>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Garanties" label-for="dni">
          <b-form-input v-model="policy_data.guarantees" placeholder="Garanties"
                        :class="validation_policy_data.guarantees ? 'is-invalid': ''"/>
          <small v-if="validation_policy_data.guarantees" class="text-danger"> {{ validation_policy_data.guarantees }}</small>
        </b-form-group>
      </b-col>
    </b-row>
</template>

<script>
import {BFormGroup, BFormInput, BRow, BCol, BOverlay} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
  getterType: 'insurancePolicies/getField',
  mutationType: 'insurancePolicies/updateField',
})

export default {
  name: "CarMotocycle",
  components: {
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BFormInput,

    vSelect,
  },
  data() {
    return {
      showLoading: false,
      activities: [],
    }
  },
  computed: {
    ...mapState('insurancePolicies', ['validation_policy_data']),
    ...mapFields({
      policy_data: 'policy_data'
    }),
  },
}
</script>